<template>
     <div class="line-up__wrapper" v-show="!isShowNoData">

         <!-- line-up fields -->
        <div class="line-up__field" v-if="lineUp.showLineUpField">
            <div class="match-detail__content-title has-space" style="paddinf-left:0.5rem">{{$t('MATCH_LINEUPS')}}</div>
            <div class="field-wrapper">
                <Navigation :id="lineUpField.home.teamId" :category="'team'" :name="lineUpField.home.teamName" class="field-team-info">
                    <span class="field-team-info__team-name">{{lineUpField.home.teamName}}</span>
                    <span>{{lineUpField.home.strategy}}</span>
                </Navigation>
                <div class="field-player">
                    <div class="field-player__row">
                        <div class="field-player__row-wrapper" v-for="(o, index) in lineUpField.home.playerList" :key="index">
                            <Navigation :id="level2.playerId" :category="'player'"  :name="level2.name" v-for="(level2, index) in o.child" :key="index" class="field-player__row-container" >
                                <div class="field-player__row-container__icon">
                                    <div class="field-player__row-container__icon-img">
                                        <Icon :category="'player'"  :logoUrl=level2.logo  ></Icon>
                                        <!-- <img :src="`${s3ImgUrl}/player/${level2.playerId}.png`" @error="replaceByDefault"> -->
                                        <div class="field-player__row-container__icon-card" >                                      
                                            <div class="match__event-type redcard" v-if="level2.redCard"></div>     
                                            <div class="match__event-type yellowcard" v-if="level2.yellowCard"></div>
                                            <div class="match__event-type yellowred" v-if="level2.yellowRed"></div>
                                        </div>
                                          <div class="field-player__row-container__icon-card-left top">
                                            <div class="match__event-type ownGoal" v-if="level2.ownGoal"></div>
                                            <div class="match__event-type goal" v-if="level2.goal"></div>
                                            <div class="match__event-type var" v-if="level2.var"></div>
                                            <div class="match__event-type miss" v-if="level2.penMiss"></div>
                                            <div class="match__event-type penaltyGoal" v-if="level2.penaltyGoal"></div>
                                            <div class="match__event-type miss" v-if="level2.penaltyPenMiss"></div>
                                          </div>
                                       <div class="field-player__row-container__icon-card-left">  
                                            <div class="match__event-type subst" v-if="level2.subst"></div>
                                       </div>
                                    </div>
                                </div>
                                <div class="field-player__row-container__title">{{level2.name}}</div>
                            </Navigation>
                        </div>
                    
                    </div>
                    <div class="field-player__row away">
                        <div class="field-player__row-wrapper" v-for="(o, index) in lineUpField.away.playerList" :key="index">
                            <Navigation :id="level2.playerId" :category="'player'" v-for="(level2, index) in o.child" :key="index" class="field-player__row-container" >
                                <div class="field-player__row-container__icon">
                                    <div class="field-player__row-container__icon-img">
                                        <Icon :category="'player'" :logoUrl=level2.logo  ></Icon>
                                        <!-- <img :src="`${s3ImgUrl}/player/${level2.playerId}.png`" @error="replaceByDefault"> -->

                                        <div class="field-player__row-container__icon-card">
                                            <div class="match__event-type redcard" v-if="level2.redCard"></div>
                                            <div class="match__event-type yellowcard" v-if="level2.yellowCard"></div>
                                            <div class="match__event-type yellowred" v-if="level2.yellowRed"></div>
                                        </div>
                                          <div class="field-player__row-container__icon-card-left top">
                                              
                                            <div class="match__event-type ownGoal" v-if="level2.ownGoal"></div>
                                            <div class="match__event-type goal" v-if="level2.goal"></div>
                                            <div class="match__event-type var" v-if="level2.var"></div>
                                            <div class="match__event-type miss" v-if="level2.penMiss"></div>
                                            <div class="match__event-type penaltyGoal" v-if="level2.penaltyGoal"></div>
                                            <div class="match__event-type miss" v-if="level2.penaltyPenMiss"></div>
                                          </div>
                                        <div class="field-player__row-container__icon-card-left">
                                            <div class="match__event-type subst" v-if="level2.subst"></div>
                                       </div>
                                    </div>
                                </div>
                                <div class="field-player__row-container__title">{{level2.name}}</div>
                            </Navigation>
                        </div>
                    </div>
                    <div class="field-player__line-corner__wrapper">
                        <div class="field-player__line-corner__child top-left"></div>
                        <div class="field-player__line-corner__child top-right"></div>
                        <div class="field-player__line-corner__child bottom-left"></div>
                        <div class="field-player__line-corner__child bottom-right"></div>
                    </div>
                    <div class="field-player__line-center__wrapper">
                        <div class="field-player__line-center__line"></div>
                        <div class="field-player__line-center__dot"></div>
                        <div class="field-player__line-center__circle"></div>
                    </div>
                    <div class="field-player__line-penalty__wrapper top">
                        <div class="field-player__line-penalty__1"></div>
                        <div class="field-player__line-penalty__2"></div>
                        <div class="field-player__line-penalty__3"></div>
                    </div>           
                    <div class="field-player__line-penalty__wrapper bottom">
                        <div class="field-player__line-penalty__1"></div>
                        <div class="field-player__line-penalty__2"></div>
                        <div class="field-player__line-penalty__3"></div>
                    </div>          
                </div>
                <Navigation :id="lineUpField.away.teamId" :category="'team'" :name="lineUpField.away.teamName" class="field-team-info">
                    <span>{{lineUpField.away.teamName}}</span>
                    <span>{{lineUpField.away.strategy}}</span>
                </Navigation>
            </div>
        </div>
            <div class="substitube-wrapper" v-if="lineUp.showPrePlayerList">
                <div class="substitube-container">
                    <div class="substitube-title">
                        <Navigation :id="matchDetailScore.homeTeamId" :category="'team'" :name="matchDetailScore.homeTeamName">
                            <Icon :category="'team'" :logoUrl=matchDetailScore.homeLogo  ></Icon>
                        </Navigation>
                        <div>{{$t('PLAYERS')}}</div>
                        <Navigation :id="matchDetailScore.awayTeamId" :category="'team'" :name="matchDetailScore.awayTeamName">
                            <Icon :category="'team'" :logoUrl=matchDetailScore.awayLogo  ></Icon>
                        </Navigation>
                    </div>
                    <div class="substitube-player">
                        <div class="flex-1">
                            <div class="substitube-player__container" style="min-height:3rem" v-for="(o, index) in prePlayerList.home" :key="index">
                                <div class="substitube-player__container-icon" v-if="o.eventId !==''">
                                    <img v-if="o.status" src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name" v-if="o.eventId !==''">
                                    <Navigation :id="o.playerId" :category="'player'" :name="o.playerName"  class="field-player__row-container">
                                        <p>{{o.playerName}}</p>
                                    </Navigation>
                                </div>
                            </div>
                        </div>
                      
                        <div class="flex-1">
                            <div class="substitube-player__container" style="min-height:3rem" v-for="(o, index) in prePlayerList.away" :key="index">
                                <div class="substitube-player__container-icon">
                                    <img v-if="o.status" src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name">
                                    <Navigation :id="o.playerId" :category="'player'" :name="o.playerName" class="field-player__row-container">
                                        <p>{{o.playerName}}</p>
                                    </Navigation>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        <!-- Substituitions & SubstitutionPlayers -->
        <div class="">
            <div class="substitube-wrapper" v-if="lineUp.showSubstituitions">
                <div class="substitube-container">
                    <div class="substitube-title">
                        <Navigation :id="matchDetailScore.homeTeamId" :category="'team'" :name="matchDetailScore.homeTeamName">
                            <Icon :logoUrl=matchDetailScore.homeLogo  ></Icon>
                        </Navigation>
                        <div>{{$t('SUBSTITUTIONS')}}</div>
                        <Navigation :id="matchDetailScore.awayTeamId" :category="'team'" :name="matchDetailScore.awayTeamName">
                            <Icon :logoUrl=matchDetailScore.awayLogo  ></Icon>
                        </Navigation>
                    </div>
                    <div class="substitube-player">
                        <div class="flex-1">
                            <div class="substitube-player__container" v-for="(o, index) in substituitions.home" :key="index" >
                                <div class="substitube-player__container-time" v-if="o.eventId !==''">{{o.eventMinute}}"</div>
                                <div class="substitube-player__container-icon" v-if="o.eventId !==''">
                                    <img src="../../../../static/images/icons/icon_substitube_arrow_down.png">
                                    <img src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name" v-if="o.eventId !==''">
                                    <Navigation :id="o.playerOutId" :category="'player'" :name="o.playerOutName" class="field-player__row-container">
                                            <p>{{o.playerOutName}}</p>
                                    </Navigation>
                                    <Navigation :id="o.playerInId" :category="'player'" :name="o.playerInName" class="field-player__row-container">
                                            <p>{{o.playerInName}}</p>
                                    </Navigation>
                                </div>
                            </div>                           
                        </div>
                    
                        <div class="flex-1">                         
                            <div class="substitube-player__container right" v-for="(o, index) in substituitions.away" :key="index" >
                                <div class="substitube-player__container-time"  v-if="o.eventId !==''">{{o.eventMinute}}"</div>
                                <div class="substitube-player__container-icon"  v-if="o.eventId !==''">
                                    <img src="../../../../static/images/icons/icon_substitube_arrow_down.png">
                                    <img src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name">
                                    <Navigation :id="o.playerOutId" :category="'player'" :name="o.playerOutName" class="field-player__row-container">
                                        <p>{{o.playerOutName}}</p>
                                    </Navigation>
                                    <Navigation :id="o.playerInId" :category="'player'" :name="o.playerInName"  class="field-player__row-container">
                                        <p>{{o.playerInName}}</p>
                                    </Navigation>
                                </div>
                            </div>                         
                        </div>
                    </div>

                </div>
            </div>
             <div class="substitube-wrapper" v-if="lineUp.showSubstitutionPlayers">
                <div class="substitube-container">
                    <div class="substitube-title">
                         <Navigation :id="matchDetailScore.homeTeamId" :category="'team'" :name="matchDetailScore.homeTeamName">
                            <Icon  :logoUrl=matchDetailScore.homeLogo ></Icon>
                        </Navigation>
                        <div>{{$t('SUBSTITUTE_PLAYERS')}}</div>
                        <Navigation :id="matchDetailScore.awayTeamId" :category="'team'" :name="matchDetailScore.awayTeamName">
                            <Icon :logoUrl=matchDetailScore.awayLogo ></Icon>
                        </Navigation>
                    </div>
                    <div class="substitube-player">
                        <div class="flex-1">
                            <div class="substitube-player__container" style="min-height:3rem" v-for="(o, index) in substitutionPlayers.home" :key="index">
                                <div class="substitube-player__container-icon" v-if="o.eventId !==''">
                                    <img v-if="o.status" src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name">
                                    <Navigation :id="o.playerId" :category="'player'" :name="o.playerName"  class="substitube-player__container-name">
                                        <p>{{o.playerName}}</p>
                                    </Navigation>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="substitube-player__container substitube-player__right" style="min-height:3rem" v-for="(o, index) in substitutionPlayers.away" :key="index">
                                <div class="substitube-player__container-icon">
                                    <img v-if="o.status" src="../../../../static/images/icons/icon_substitube_arrow_up.png">
                                </div>
                                <div class="substitube-player__container-name">
                                    <Navigation :id="o.playerId" :category="'player'" :name="o.playerName" class="substitube-player__container-name">
                                        <p>{{o.playerName}}</p>
                                    </Navigation>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Coaches -->
        <div class="substitube-wrapper" v-if="lineUp.showCoachs">
            <div class="substitube-container">
                <div class="substitube-title">
                     <Navigation :id="matchDetailScore.homeTeamId" :category="'team'" :name="matchDetailScore.homeTeamName">
                        <Icon  :logoUrl=matchDetailScore.homeLogo   ></Icon>
                    </Navigation>
                    <div>{{$t('COACHS')}}</div>
                    <Navigation :id="matchDetailScore.awayTeamId" :category="'team'" :name="matchDetailScore.awayTeamName">
                        <Icon :logoUrl=matchDetailScore.awayLogo ></Icon>
                    </Navigation>
                </div>
                <div class="substitube-coach">
                   <div >{{substitutionCoachesHome === "" ? '-' : substitutionCoachesHome}}</div>
                   <div class="substitube-coach__fake"></div>
                   <div >{{substitutionCoachesAway === "" ? '-' : substitutionCoachesAway}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="no-data" v-show="isShowNoData">{{$t('NO_LINEUPS')}}</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import config from '@/js/config.js'
export default {
    data() {
        return {
            isShowNoData:false,
            s3ImgUrl:config.s3ImgUrl,
            matchId:'',

            lineUp:{
                showSubstituitions:false,
                showSubstitutionPlayers:false,
                showCoachs:false,
                showLineUpField:false,
                showPrePlayerList:false
            },

            lineUpField:{},   
            prePlayerList:[],             
            substitubeList:[],
            substituitions:[],
            substitutionPlayers:[],
            substitutionCoachesHome:'',
            substitutionCoachesAway:'',

        }
    },
    created() {
         this.matchId = this.$route.params.matchId;
        
    },
    mounted(){
         this.getMatchDetailLineUpData();
    },
    computed:{
          ...mapGetters([              
                'isMobile',
                'matchDetailScore',
                'currentSportType',
                'currentLocale'
           ]),
    },

    methods:{
        ...mapActions([
            "getMatchDetailLineUp",
            
        ]),

        processDataEvently(home, away, target){
        // make home and away data evently 
            if (home.length > away.length) {
            
                let diff = home.length - away.length
                for (let i=0; i<diff ;i++) {
                    let obj={
                        eventId:''
                    };
                    away.push(obj)
                }    
                target.away = away   
            }

            if (home.length < away.length) {
            
                let diff = -(home.length - away.length)
                for (let i=0; i<diff ;i++) {
                    let obj={
                        eventId:''
                    };
                    home.push(obj)
                }    
                target.home = home 
            }
            
        },

        async getMatchDetailLineUpData(){
            let params = {
                matchId: this.matchId
            }
            
            const result = await this.getMatchDetailLineUp(params)

            let prePlayerListHome = "";
            let prePlayerListAway = "";

            let substituitionsHome = "";
            let substituitionsAway = "";
          
            let substitutionPlayersHome = "";
            let substitutionPlayersAway = "";

            if (result.result === null) { // if all field is not data direct show no data
                this.isShowNoData = true;
                 return
            }
            
            if (result.result.lineUpField === null && result.result.prePlayerList != null) { //if show prePlayerList (lineupfield no data)
                this.lineUp.showLineUpField = false;
                this.lineUp.showPrePlayerList = true;
                let prePlayerList = result.result.prePlayerList;
                
                prePlayerListHome = prePlayerList.home;
                prePlayerListAway = prePlayerList.away;

                this.prePlayerList.home = prePlayerListHome;
                this.prePlayerList.away = prePlayerListAway;
                this.processDataEvently(prePlayerListHome, prePlayerListAway, this.prePlayerList)

            } else if (result.result.lineUpField != null && result.result.prePlayerList === null) { // show lineupfield (prePlayerList no data)
                this.lineUp.showPrePlayerList = false;
                
                this.lineUpField = result.result.lineUpField
                if( result.result.lineUpField.away.playerList.length === 0 && result.result.lineUpField.home.playerList.length === 0){
                    this.lineUp.showLineUpField = false;
                } else{
                    this.lineUp.showLineUpField = true;
                }
            } else if (result.result.lineUpField === null && result.result.prePlayerList === null){ //lineupfield && prePlayerList no data
                this.lineUp.showPrePlayerList = false;
                this.lineUp.showLineUpField = false;
                
                this.lineUpField = result.result.lineUpField;
            } 
           

            //coach
            let coaches = result.result.coaches;

            if (coaches === null) {
                this.lineUp.showCoachs = false;
            } else {
                this.lineUp.showCoachs = true;

                this.substitutionCoachesHome =  coaches.home.coachName;
                this.substitutionCoachesAway =  coaches.away.coachName;
            }

            //substitution
            let substituitions = result.result.substituitions;
            if (substituitions === null) {
                this.lineUp.showSubstituitions=false;
            }else {
                this.lineUp.showSubstituitions=true; 

                substituitionsHome = substituitions.home;
                substituitionsAway = substituitions.away;
               
                this.substituitions.home = substituitionsHome;// if home and away data length are evently 
                this.substituitions.away = substituitionsAway;

                this.processDataEvently(substituitionsHome, substituitionsAway, this.substituitions)         
            }

            //player            
            let substitutionPlayers = result.result.substitutionPlayers; 
            if (substitutionPlayers === null) {
                this.lineUp.showSubstitutionPlayers=false;
            } else {
                this.lineUp.showSubstitutionPlayers=true;  

                substitutionPlayersHome = substitutionPlayers.home;
                substitutionPlayersAway = substitutionPlayers.away;

                this.substitutionPlayers.home= substitutionPlayersHome;
                this.substitutionPlayers.away = substitutionPlayersAway;

                this.processDataEvently(substitutionPlayersHome, substitutionPlayersAway, this.substitutionPlayers)         
            }
        },
        replaceByDefault(e) { 
            e.target.src = `${config.s3ImgUrl}/player/default.png`
        },
    }
}
</script>


<style>
    .line-up__wrapper{
        display:flex;
        flex-direction: column;
    }
    .field-wrapper{
        background-color: #39632C;
        padding: .5rem;
        margin: 0 1rem;
        border-radius: .5rem;
        margin-bottom: 1rem;
        height: 46rem;
    }
    .field-team-info{
        display: flex;
        color: #fff;
        justify-content: space-between;
        text-shadow: 1px 1px #000;
        box-sizing: border-box;
    }
    .field-team-info__team-name:hover{
        color: var(--color-hover);
    }
    .field-player{
        position: relative;
        margin: .5rem .25rem;
        overflow: hidden;
        height: 90%;
        border: 4px solid #88b37a;
        background: repeating-linear-gradient( 180deg ,#39632C,#39632C 10%,#528b40 0,#528b40 20%);
    }
    .field-player__row{
        display: flex;
        position: absolute;
        z-index: 2;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        padding: .5rem;
        height: 50%;
        box-sizing: border-box;
    }
    .field-player__row.away{
        top:50%
    }
    .field-player__row-wrapper, .field-player__away{
         display: flex;
        align-items: flex-start;
        color: #fff;
    }
    .field-player__row-container:hover .field-player__row-container__title{
        color: var(--color-hover)
    }
    .field-player__row-container{
        flex: 1 1;
    }
    .field-player__row-container__title{
        display: flex;
        font-size: .68rem;
        color: #fff;
        text-align: center;
        justify-content: center;
        margin-top: .25rem;
        text-shadow: 1px 1px #000;
    }
    .field-player__row-container__icon{
        display: flex;
        justify-content: center;
        text-align: center;
        flex: 1 1;
    }
    .field-player__row-container__icon-img{
        position: relative;
        display: flex;
        height: 2rem;
        width: 2rem;
        justify-content: center;
        font-weight: 700;
        align-items: center;
        font-size: .75rem;
        border-radius: 50%;
        position: relative;
        border: 1px solid #fff;
    }
      .field-player__row-container__icon-img .icon-player{
        border-radius: 50%;
        overflow: hidden;
        width:100%;
        height:100%
    }
  .field-player__row-container__icon-card{
      position: absolute;
      top: -.1rem;
      right: -.6rem;
  }
  .field-player__row-container__icon-card-left{
    position: absolute;
    bottom: 0;
    left: -.8rem;
  }
    .field-player__row-container__icon-card-left.top{
        position: absolute;
        top: 0;
        left: -.8rem;
    }
    .yellow-card{
        width: 1rem;
         height: 1rem;
         background: url('../../../../static/images/lineup/yellowcard.png') center/contain no-repeat;
    }
    .field-player__line-corner__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .field-player__line-corner__child{
        position: absolute;
        border: 4px solid #88b37a;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 9999px;
    }
    .field-player__line-corner__child.top-left{
        top: -20px;
        left: -20px;
    }
    .field-player__line-corner__child.top-right{
        top: -20px;
        left: calc(100% + -20px);
    }
     .field-player__line-corner__child.bottom-left{
        bottom: -20px;
        left: calc(100% + -20px);
    }
    .field-player__line-corner__child.bottom-right{
        bottom: -20px;
        left: -20px;
    }
    .field-player__line-center__wrapper{
         position: relative;
         top:-50%;
    }
    .field-player__line-center__line{
        position: absolute;
        width: 100%;
        border: 2px solid #88b37a;
        top: calc(50% - 2px);
    }
    .field-player__line-center__dot{
        position: absolute;
        width: .75rem;
        height: .75rem;
        border-radius: 9999px;
        background-color: #88b37a;
        left: calc(50% - .375rem);
        top: calc(50% - .375rem);
    }
    .field-player__line-center__circle{
        position: absolute;
        width: 6rem;
        height: 6rem;
        border-radius: 9999px;
        border: 4px solid #88b37a;
        left: calc(50% - 3rem);
        top: calc(50% - 3rem);
    }
    .field-player__line-penalty__wrapper{
        position: relative;
        height: 10%;
        width: 50%;
        left: calc(50% - 25%);
        z-index: 1;
    }
    .field-player__line-penalty__wrapper.top{
        top:-100%;
    }
    .field-player__line-penalty__wrapper.bottom{
        top:-20%;
    }
    .field-player__line-penalty__1{
        position: absolute;
        width: 100%;
        border: 4px solid #88b37a;
        height:100%;
        background-color: #528b40;
        z-index: 1;
    }
    .top .field-player__line-penalty__1{
        border-top:0;
    }

    .bottom .field-player__line-penalty__1{
        border-bottom:0;
    }

    .field-player__line-penalty__2{
        position: absolute;
        width: 50%;
        left: calc(50% - 25%);
        height: 1.5rem;
        border: 4px solid #88b37a;
        z-index: 2;
    }
    .top .field-player__line-penalty__2{
        border-top:0;
    }
    .bottom .field-player__line-penalty__2{   
        border-bottom: 0;
        bottom: 0;
    }
    .field-player__line-penalty__3{
        position: absolute;
        left: 24.5%;
        border: 4px solid #88b37a;
        border-radius: 50%;
        width: 50%;
        height: 2.5rem;
    }
    .top .field-player__line-penalty__3{
        top: 3.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: 0;
    }
    .bottom .field-player__line-penalty__3{
        bottom: 3.2rem;
        border-bottom-width: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        top: auto;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-top-width: 4px
    }
    .substitube-wrapper{
        padding: 0 1rem;
    }
     .substitube-container{

     }
    .substitube-title{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 1rem;
    }
    .substitube-player{
        display: flex;
        /* flex-direction: column; */
         flex-flow: row wrap;
        padding-bottom: 1rem;
        justify-content: center;
      
    }
    .substitube-player__wrapper{
        display:flex;
        border-bottom: .1rem solid var(--color-grey-20);
    }
    .substitube-player__container{
        display: flex;
        align-items: center;
        min-height: 5rem;
        color: var(--color-grey-light);
        font-size:.7rem;
        width: 100%;
        padding-bottom: .8rem;
        padding-top: .5rem;
        box-sizing: border-box;
        border-bottom: .1rem solid var(--color-grey-20);
        padding-left: 6.5vw;
    }
    .substitube-player__container.right{
         padding-left: 11vw;
    }
     .substitube-player__right{
        padding-left: 13.5vw;
    }
    .substitube-player__container-time{
        margin-right: .8rem;
        width: 1rem;
    }
     .substitube-player__container-time>p{
         margin-bottom: .3rem;
     }
    .substitube-player__container-icon{
        display: flex;
        flex-direction: column;
        width: 1.2rem;
    }
     .substitube-player__container-icon>img{
        margin-bottom: .3rem;
        width: .6rem;
     }
      .substitube-player__container-name{
         color:white;
     }
     .substitube-player__container-name a{
         color:white;
     }
    .substitube-player__container-name a:hover{
        color: var(--color-hover)
     }
     .substitube-coach{
        display: flex;
        justify-content: space-evenly;
        color: var(--color-grey-light);
        font-size: .7rem;
        padding:1.5rem 
     }
     .substitube-coach__fake{
         width: 5rem;
     }
    @media (min-width: 400px ){
          .substitube-player__container{
            padding-left: 7.5vw;
        }
        .substitube-player__container.right{
            padding-left: 14.5vw;
        }
        .substitube-player__right{
            padding-left: 16.5vw;
        }
    }
    @media (min-width: 540px ){
          .substitube-player__container{
            padding-left: 10.5vw;
        }
        .substitube-player__container.right{
            padding-left: 15.5vw;
        }
        .substitube-player__right{
            padding-left: 18.5vw;
        }
    }
    @media (min-width: 768px ){
          .substitube-player__container{
            padding-left: 13.5vw;
        }
        .substitube-player__container.right{
            padding-left: 17.5vw;
        }
        .substitube-player__right{
            padding-left: 19.5vw;
        }
    }
      
    @media (min-width: 1200px){
        .substitube-player__container{
            padding-left: 2.5vw;
        }
        .substitube-player__container.right{
            padding-left: 2.5vw;
        }
        .line-up__wrapper{
            flex-direction: row;
                
        }
        .line-up__field{
            width: 33%;
        }
        .line-up__field-title{
            margin: 0 0 2rem 1rem;
            font-size: .88rem;
        }
        .substitube-wrapper{
            padding: 0 1rem;
            width:22rem
        }
    }
</style>